@use '@/scss/underscore' as _;
@use '@logto/core-kit/scss/fonts';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: var(--color-base);
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

input {
  border: none;
  outline: none;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar:horizontal {
  height: 16px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-neutral-variant-80);
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 12px;
  min-height: 40px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.appInsightsWrapper {
  width: 100%;
  height: 100%;
  flex: 1;
}

.ReactModalPortal {
  position: relative;
  // Set `z-index` for the portal to ensure it can be placed on the right layer among other
  // top-level doms.
  @include _.z-index(modal);
}
